
import { ButtonContent } from "../global/boton/ButtonContent";
import { GiRibbonMedal, GiVacuumCleaner } from "react-icons/gi";
import { AiOutlineTeam } from "react-icons/ai";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full grid md:grid-cols-2 grid-cols-1 justify-center items-center md:pb-[180px] md:pt-[100px] pt-[100px] pb-[100px]">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white md:text-start text-center  text-[35px] md:text-[60px]">
              {title}
            </h1>
            <p className="text-white md:text-start text-center ">
              {texts}
            </p>
            <div className="w-full flex md:justify-start justify-center py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>
          <div className="flex justify-center">
            <img 
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F11%20Años.png?alt=media&token=1daa973e-5b60-4d4e-8aa9-347fd07c53a2" 
            alt="años"
            className="md:w-[350px] md:h-[350px] w-[150px] h-[150px] md:-mt-14 mt-10"
            />
          </div>
        </div>
      </div>
      <div className=" md:w-[70%] w-[100%] bg2 text-white md:-mt-20 -mt-20 relative md:py-14 py-2 homi">
        <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
          <h5 className="flex items-center" data-aos="zoom-in">
            <GiVacuumCleaner
              fontSize={70}
              className="text-white"
            />
            BETTER SERVICE</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
            <AiOutlineTeam
              fontSize={70}
              className="text-white"
            />
            PROFESSIONAL TEAM</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
            <GiRibbonMedal
              fontSize={70}
              className="text-white"
            />BEST QUALITY</h5>
        </ul>
      </div>
    </>
  );
}

export default HeroSection;
